import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Slide,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");

  return (
    <Stack className="contactus" id="contact">
      <Typography className="h3" variant="h3" style={{ fontFamily: "IZYMODE" }}>
        Contact
        <br />
        <div className="underline"></div>
      </Typography>
      <a
        href="tel:+91-8369220494"
        tabIndex="-1"
        data-restore-tabindex="0"
        style={{ textAlign: "center", color: "#000" }}
      >
        <span style={{ fontStyle: "italic" }}>
          <span id="appt_txt">
            <span className="color_14">
              <span style={{ textDecoration: "underline" }}>
                <span
                  style={{
                    fontFamily:
                      "avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif",
                  }}
                >
                  <h6
                    style={{
                      letterSpacing: "0.05em",
                      fontWeight: "bold",
                    }}
                    className="appt_text"
                  >
                    CALL FOR APPOINTMENTS : +91 - 8369220494
                  </h6>
                </span>
              </span>
            </span>
          </span>
        </span>
      </a>
      <Grid container>
        <Grid item xs={12} sm={12} className="contact_form">
          <form method="post" action="enquiry-form.php">
            <Grid container spacing={4} className="textfields">
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="outlined-required"
                  label="Name"
                  value={name}
                  name="name"
                  fullWidth
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="outlined-required"
                  label="Email"
                  value={email}
                  name="email"
                  fullWidth
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="outlined-required"
                  label="Mobile Number"
                  value={mobile}
                  name="mobile"
                  fullWidth
                  inputProps={{ minLength: 10, maxLength: 10 }}
                  onChange={(e) => setMobile(e.target.value)}
                  type="tel"
                />
              </Grid>

              <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                <FormControlLabel
                  control={<Checkbox required defaultChecked />}
                  label={
                    <label>
                      I accept{" "}
                      <Link
                        href="/privacy-policy.php"
                        rel="noopener noreferrer"
                        style={{ color: "#1976d2" }}
                      >
                        terms and conditions{" "}
                      </Link>
                      and the
                      <Link
                        href="/privacy-policy.php"
                        rel="noopener noreferrer"
                        style={{ color: "#1976d2" }}
                      >
                        {" "}
                        privacy policy
                      </Link>
                    </label>
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                <Button
                  name="scontact"
                  type="submit"
                  id="scontact"
                  variant="contained"
                  className="btn ss-btn active btn-16"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Stack>
  );
}
