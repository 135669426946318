import { Grid, Stack, Typography } from "@mui/material";
import img1 from "../images/gallery/g1.webp";
import img2 from "../images/gallery/g2.webp";
import img3 from "../images/gallery/g3.webp";
import img4 from "../images/gallery/g4.webp";
import img5 from "../images/gallery/g5.webp";
import img6 from "../images/gallery/g6.webp";

export default function Gallery() {
  return (
    <Stack id="gallery">
      <Typography
        className="h3"
        variant="h3"
        style={{
          fontWeight: "bold",
          textAlign: "center",
          padding: "50px",
          fontFamily: "IZYMODE",
        }}
      >
        Gallery
        <br />
        <div
          className="underline"
          style={{
            background: "#37a692",
            height: "4px",
            width: "200px",
            textAlign: "center",
            margin: "auto",
          }}
        ></div>
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <img
            lodable="lazy"
            src={img1}
            style={{
              width: "100%",
              height: "300px",
              boxShadow: "0px 6px 14px",
            }}
            alt="gallery_img"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <img
            lodable="lazy"
            src={img2}
            style={{
              width: "100%",
              height: "300px",
              boxShadow: "0px 6px 14px",
            }}
            alt="gallery_img"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <img
            lodable="lazy"
            src={img3}
            style={{
              width: "100%",
              height: "300px",
              boxShadow: "0px 6px 14px",
            }}
            alt="gallery_img"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <img
            lodable="lazy"
            src={img4}
            style={{
              width: "100%",
              height: "300px",
              boxShadow: "0px 6px 14px",
            }}
            alt="gallery_img"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <img
            lodable="lazy"
            src={img5}
            style={{
              width: "100%",
              height: "300px",
              boxShadow: "0px 6px 14px",
            }}
            alt="gallery_img"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <img
            lodable="lazy"
            src={img6}
            style={{
              width: "100%",
              height: "300px",
              boxShadow: "0px 6px 14px",
            }}
            alt="gallery_img"
          />
        </Grid>
      </Grid>
    </Stack>
  );
}
