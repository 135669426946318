import { useEffect, useState } from "react";
import logo from "../images/piramal_logo.svg";
export default function Navbar() {
  const [show, setShow] = useState(true);
  const controlNavbar = () => {
    if (window.scrollY > 100) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  return (
    <nav
      className={`  ${show && "navbar fixed-top navbar-expand-sm navbar-dark"}`} style={{backgroundColor:"white", color:"black"}}
    >
      <div className="container nav-container" style={{backgroundColor:"white", color:"black"}}>
        <a className="navbar-brand" href="/">
          <img loadable="lazy" src={logo} alt="logo" className="logoImg" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link" href="#overview">
                Overview
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="#floorplan">
                Floor Plan
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#pricing">
                Pricing
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#gallery">
                Gallery
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="#contact">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
      <br />
    </nav>
  );
}
