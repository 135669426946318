import { Grid, Stack, Typography } from "@mui/material";
import img from "../images/location.webp";
import {useEffect} from "react";
import GoogleMapReact from 'google-map-react';
const AnyReactComponent = ({ text }) => <div>{text}</div>;
export default function ProjectConnectivity() {
  const defaultProps = {
     
    center: {
      lat: 19.16753928172579,
      lng: 72.93609953068916
    },
    zoom: 11
  };
  return (
    <Stack id="projectConnectivity">
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          style={{ margin: "auto", textAlign: "center" }}
        >
          <Typography variant="h3" style={{ fontFamily: "IZYMODE" }}>
            Project Connectivity
            <br />
            <div className="underline"></div>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          style={{ display: "flex", justifyContent: "center" }}
        >
         <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent
          lat={ 19.16753928172579}
          lng={72.93609953068916}
          text="Piramal Revanta Sales Office"
        />
      </GoogleMapReact>
    </div>
         
        </Grid>
        <Grid item xs={12} sm={6} style={{ margin: "auto" }}>
          <Typography
            className="text"
            variant="subtitle1"
            style={{ fontWeight: "bold", padding: "10px 30px" }}
          >
            
 
            • Sonapur Metro Station - 5 mins
            <br />
            <br />
            • Nahur Railway Station - 7 mins
 
            <br />
            <br />
            • Eastern Express Highway - 18 mins
 
            <br />
            <br />
            • Goregaon Mulund Link Road - 4 mins
 
            <br />
            <br />
            • Fortis Hospital - 3 mins
            
            <br />
            <br />
            • EuroKids Pre School - 5 mins
          </Typography>
        </Grid>
      </Grid>
    </Stack>
  );
}
