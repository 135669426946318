import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Slide,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import floorplan from "../images/floorplans/floorplan1.webp";
import logo from "../images/piramal_logo.svg";
import CloseIcon from "@mui/icons-material/Close";
export default function FloorPlan() {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const [open, setOpen] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Stack class="floorplan" id="floorplan">
      <Typography
        className="h3"
        variant="h3"
        style={{
          fontWeight: "bold",
          textAlign: "center",
          padding: "50px",
          fontFamily: "IZYMODE",
        }}
      >
        Floorplans
        <br />
        <div
          className="underline"
          style={{
            background: "#37a692",
            height: "4px",
            width: "250px",
            textAlign: "center",
            margin: "auto",
          }}
        ></div>
      </Typography>
      {/* <Carousel
        responsive={responsive}
        style={{ textAlign: "center !important", alignItems: "center" }}
      >
        <img loadable="lazy" src={floorplan} alt="floorplan_img" />
        <img loadable="lazy" src={floorplan} alt="floorplan_img" />
        <img loadable="lazy" src={floorplan} alt="floorplan_img" />
      </Carousel>

      <Button variant="contained" onClick={handleClickOpen} className="btn">
        Download FloorPlans
      </Button> */}

      <Grid
        container
        className="container1"
        style={{ justifyContent: "center" }}
        spacing={2}
      >
        <Grid sm={4} xs={12}>
          <Stack style={{ position: "relative", alignItems: "center" }}>
            <img
              lodable="lazy"
              src={floorplan}
              alt="floorplan_img"
              style={{
                position: "relative",
                // margin: "20px",
                border: "1px solid",
                filter: "blur(3px)",
              }}
            />
            <Stack
              style={{
                position: "absolute",
                textAlign: "center",
                bottom: "25%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h3" style={{ fontWeight: "bold" }}>
                3 BHK
              </Typography>

              <Button
                variant="contained"
                onClick={handleClickOpen}
                className="btn"
              >
                Download FloorPlan
              </Button>
            </Stack>
          </Stack>
        </Grid>
        <Grid sm={4} xs={12}>
          <Stack style={{ position: "relative", alignItems: "center" }}>
            <img
              lodable="lazy"
              src={floorplan}
              alt="floorplan_img"
              style={{
                position: "relative",
                // margin: "20px",
                border: "1px solid",
                filter: "blur(3px)",
              }}
            />
            <Stack
              style={{
                position: "absolute",
                textAlign: "center",
                bottom: "25%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h3" style={{ fontWeight: "bold" }}>
                4 BHK
              </Typography>

              <Button
                variant="contained"
                onClick={handleClickOpen}
                className="btn"
              >
                Download FloorPlan
              </Button>
            </Stack>
          </Stack>
        </Grid>
        <Grid sm={4} xs={12}>
          <Stack style={{ position: "relative", alignItems: "center" }}>
            <img
              lodable="lazy"
              src={floorplan}
              alt="floorplan_img"
              style={{
                position: "relative",
                // margin: "20px",
                border: "1px solid",
                filter: "blur(3px)",
              }}
            />
            <Stack
              style={{
                position: "absolute",
                textAlign: "center",
                bottom: "25%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h3" style={{ fontWeight: "bold" }}>
                5 BHK
              </Typography>

              <Button
                variant="contained"
                onClick={handleClickOpen}
                className="btn"
              >
                Download FloorPlan
              </Button>
            </Stack>
          </Stack>
        </Grid>

        <Grid sm={4} xs={12}>
          <Stack style={{ position: "relative", alignItems: "center" }}>
            <img
              lodable="lazy"
              src={floorplan}
              alt="floorplan_img"
              style={{
                position: "relative",
                // margin: "20px",
                border: "1px solid",
                filter: "blur(3px)",
              }}
            />
            <Stack
              style={{
                position: "absolute",
                textAlign: "center",
                bottom: "25%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h3" style={{ fontWeight: "bold" }}>
                6 BHK Duplex
              </Typography>

              <Button
                variant="contained"
                onClick={handleClickOpen}
                className="btn"
              >
                Download FloorPlan
              </Button>
            </Stack>
          </Stack>
        </Grid>

        <Grid sm={4} xs={12}>
          <Stack style={{ position: "relative", alignItems: "center" }}>
            <img
              lodable="lazy"
              src={floorplan}
              alt="floorplan_img"
              style={{
                position: "relative",
                // margin: "20px",
                border: "1px solid",
                filter: "blur(3px)",
              }}
            />
            <Stack
              style={{
                position: "absolute",
                textAlign: "center",
                bottom: "25%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h3" style={{ fontWeight: "bold" }}>
                8 BHK
              </Typography>

              <Button
                variant="contained"
                onClick={handleClickOpen}
                className="btn"
              >
                Download FloorPlan
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      {/* Dialog */}

      <div>
        <Dialog open={open} onClose={handleClose} maxWidth="xs">
          <DialogTitle
            style={{
              textAlign: "center",
              margin: "5px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <img
              lodable="lazy"
              className="logo"
              src={logo}
              alt="logo"
              width={120}
            />
            <IconButton
              aria-label="delete"
              size="large"
              style={{ outline: "none", border: "none" }}
              onClick={handleClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </DialogTitle>

          <form
            method="post"
            action="enquiry-form.php"
            style={{ padding: "20px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} style={{ display: "flex" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      style={{
                        background: "#37a692",
                        color: "#FFF",
                        padding: "3px",
                        fontWeight: "bold",
                        fontSize: "14px",
                        textAlign: "center",
                      }}
                      variant="subtitle1"
                    >
                      Discount upto 20 Lakhs*
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      style={{
                        background: "#37a692",
                        color: "#FFF",
                        padding: "3px",
                        fontWeight: "bold",
                        fontSize: "14px",
                        textAlign: "center",
                      }}
                      variant="subtitle1"
                    >
                      Best Price guaranteed
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      style={{
                        background: "#37a692",
                        color: "#FFF",
                        padding: "3px",
                        fontWeight: "bold",
                        fontSize: "14px",
                        textAlign: "center",
                      }}
                      variant="subtitle1"
                    >
                      Offer valid for limited period
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      size="small"
                      required
                      id="outlined-required"
                      label="Name"
                      value={name}
                      name="name"
                      fullWidth
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      size="small"
                      required
                      id="outlined-required"
                      label="Email"
                      value={email}
                      name="email"
                      fullWidth
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      size="small"
                      required
                      id="outlined-required"
                      label="Mobile Number"
                      value={mobile}
                      name="mobile"
                      fullWidth
                      inputProps={{ minLength: 10, maxLength: 10 }}
                      onChange={(e) => setMobile(e.target.value)}
                      type="number"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="textfields">
              {/* <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={<Checkbox required defaultChecked />}
                  label={
                    <label>
                      I accept{" "}
                      <Link
                        href="/privacy-policy.php"
                        
                        rel="noopener noreferrer"
                        style={{ color: "#1976d2" }}
                      >
                        terms and conditions{" "}
                      </Link>
                      and the
                      <Link
                        href="/privacy-policy.php"
                        
                        rel="noopener noreferrer"
                        style={{ color: "#1976d2" }}
                      >
                        {" "}
                        privacy policy
                      </Link>
                    </label>
                  }
                />
              </Grid> */}
              <Grid item xs={12} sm={12}>
                <DialogActions>
                  <Button
                    variant="contained"
                    name="scontact"
                    type="submit"
                    id="scontact"
                    className="btn ss-btn active btn-16"
                    style={{
                      background: "#37a692",
                      color: "#fff",
                      transition: "all 0.5s ease",
                      fontWeight: 600,
                      outline: "none",
                    }}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Grid>
            </Grid>
          </form>
        </Dialog>
      </div>
    </Stack>
  );
}
