import { Grid, Stack, Typography } from "@mui/material";
import overview from "../images/overview.webp";

export default function Overview() {
  return (
    <Stack className="overview" id="overview">
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ margin: "auto", textAlign: "center" }}
        >
          <img
            lodable="lazy"
            src={overview}
            alt="overview_img"
            style={{ boxShadow: "0px 6px 14px" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} style={{ margin: "auto", padding: "10px" }}>
          <Typography variant="h4" style={{ fontFamily: "IZYMODE" }}>
          New Launch Raynav at Piramal Revanta Mulund <br />
            <div className="underline"></div>
          </Typography>
          <Typography
            variant="body"
            style={{
              color: "#37a692",
              fontWeight: "bold",
            }}
          >
             Key Features:
          </Typography>
          <Typography
            className="text"
            variant="subtitle1"
            style={{
              textAlign: "left",

              fontWeight: "normal",
            }}
          >
            
A 3-Acre Paradise with just Two Towers.<br />
12,500 sqft Drop-Off Lobby Experience.<br />
50+ Amenities Curated for All Ages.<br />
Over 30% Open Green Spaces.<br />
All Residences with Private Decks.<br />
Tower 1 & Tower 2 Delivered.<br />
Phase 1 Clubhouse & Podium Amenities Nearing Completion.<br />
Elegant yet understated, homes at Vana at Piramal Revanta open windows and doors to the sounds and essence of nature.

          </Typography>
          <br />
          <Typography
            variant="body"
            style={{
              textAlign: "left",
              color: "#37a692",
              fontWeight: "bold",
            }}
          >
            
Project Highlights:
            <br />
          </Typography>
          <Typography
            variant="body"
            style={{
              textAlign: "left",

              fontWeight: "bold",
            }}
          >
            • Spread across ~12 acres.{" "}
            <br />
            • Overlooking ~25000 acres Sanjay Gandhi National Park.
            <br />
            • 2 Clubhouses, with over 35+ lifestyle amenities.
            <br />
            • Up to 50% open spaces.
            <br />
            • International collaborators.
            <br />
          </Typography>
        </Grid>
      </Grid>
    </Stack>
  );
}
