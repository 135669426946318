import React from "react";
import Home from "./components/home";
import Navbar from "./components/navbar";
import ProjectConfig from "./components/projectConfig";
import AtAGlance from "./components/atAGlance";
import Overview from "./components/overview";
import FloorPlan from "./components/floorplan";
import Pricing from "./components/pricing";
import Gallery from "./components/gallery";
import Contact from "./components/contact";
import Amenities from "./components/amenities";
import Footer from "./components/footer";
import ProjectConnectivity from "./components/projectConnectivity";

function App() {
  return (
    <>
      <Navbar />
      <Home />
      {/* <AtAGlance /> */}
      <Overview />
      <FloorPlan />
      <Pricing />
      <Amenities />
      <Gallery />
      <Contact />
      <ProjectConnectivity />
      <Footer />
    </>
  );
}

export default App;
