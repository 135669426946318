import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Slide,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import logo from "../images/piramal_logo.svg";
import CloseIcon from "@mui/icons-material/Close";
export default function Pricing() {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const [open, setOpen] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Stack id="pricing">
      <Stack id="pricing_wrapper">
        <Typography
          className="h3"
          variant="h3"
          style={{ fontFamily: "IZYMODE" }}
        >
          Pricing
          <br />
          <div className="underline"></div>
        </Typography>
        <Stack className="pricing_table">
          <div
            className="table-responsive"
            style={{ textAlign: "-webkit-center", paddingBottom: "30px" }}
          >
            <table className="table table-bordered" style={{ width: "80%" }}>
              <thead style={{ background: "rgba(0,0,0,0.7)", color: "white" }}>
                <tr>
                  <th>Configuration</th>
                  <th>Carpet Area</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody
                style={{
                  background: "rgba(0,0,0,0.5)",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                <tr>
                  <td>3 BHK</td>
                  <td>1565 sq. ft.</td>
                  <td>
                    {" "}
                    <button
                      className="btn ss-btn active btn-16"
                      data-animation="fadeInRight"
                      name="site-visit"
                      data-delay=".8s"
                      data-toggle="modal"
                      data-target="#myModal"
                      id="effect"
                      onClick={handleClickOpen}
                    >
                      View Price
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>4 BHK </td>
                  <td>2115 sq. ft.</td>
                  <td>
                    {" "}
                    <button
                      className="btn ss-btn active btn-16"
                      data-animation="fadeInRight"
                      name="site-visit"
                      data-delay=".8s"
                      data-toggle="modal"
                      data-target="#myModal"
                      id="effect"
                      onClick={handleClickOpen}
                    >
                      View Price
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>4 BHK</td>
                  <td>2496 sq. ft.</td>
                  <td>
                    {" "}
                    <button
                      className="btn ss-btn active btn-16"
                      data-animation="fadeInRight"
                      name="site-visit"
                      data-delay=".8s"
                      data-toggle="modal"
                      data-target="#myModal"
                      id="effect"
                      onClick={handleClickOpen}
                    >
                      View Price
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>4 BHK</td>
                  <td>4321 sq. ft.</td>
                  <td>
                    {" "}
                    <button
                      className="btn ss-btn active btn-16"
                      data-animation="fadeInRight"
                      name="site-visit"
                      data-delay=".8s"
                      data-toggle="modal"
                      data-target="#myModal"
                      id="effect"
                      onClick={handleClickOpen}
                    >
                      View Price
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>5 BHK</td>
                  <td>3431 sq. ft.</td>
                  <td>
                    {" "}
                    <button
                      className="btn ss-btn active btn-16"
                      data-animation="fadeInRight"
                      name="site-visit"
                      data-delay=".8s"
                      data-toggle="modal"
                      data-target="#myModal"
                      id="effect"
                      onClick={handleClickOpen}
                    >
                      View Price
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>6 BHK Duplex</td>
                  <td>7250 sq. ft</td>
                  <td>
                    {" "}
                    <button
                      className="btn ss-btn active btn-16"
                      data-animation="fadeInRight"
                      name="site-visit"
                      data-delay=".8s"
                      data-toggle="modal"
                      data-target="#myModal"
                      id="effect"
                      onClick={handleClickOpen}
                    >
                      View Price
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Stack>

        {/* Dialog */}

        <div>
          <Dialog open={open} onClose={handleClose} maxWidth="xs">
            <DialogTitle
              style={{
                textAlign: "center",
                margin: "5px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <img
                lodable="lazy"
                className="logo"
                src={logo}
                alt="logo"
                width={120}
              />
              <IconButton
                aria-label="delete"
                size="large"
                style={{ outline: "none", border: "none" }}
                onClick={handleClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </DialogTitle>

            <form
              method="post"
              action="enquiry-form.php"
              style={{ padding: "20px" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} style={{ display: "flex" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Typography
                        style={{
                          background: "#37a692",
                          color: "#FFF",
                          padding: "3px",
                          fontWeight: "bold",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                        variant="subtitle1"
                      >
                        Discount upto 20 Lakhs*
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography
                        style={{
                          background: "#37a692",
                          color: "#FFF",
                          padding: "3px",
                          fontWeight: "bold",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                        variant="subtitle1"
                      >
                        Best Price guaranteed
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography
                        style={{
                          background: "#37a692",
                          color: "#FFF",
                          padding: "3px",
                          fontWeight: "bold",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                        variant="subtitle1"
                      >
                        Offer valid for limited period
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        size="small"
                        required
                        id="outlined-required"
                        label="Name"
                        value={name}
                        name="name"
                        fullWidth
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        size="small"
                        required
                        id="outlined-required"
                        label="Email"
                        value={email}
                        name="email"
                        fullWidth
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        size="small"
                        required
                        id="outlined-required"
                        label="Mobile Number"
                        value={mobile}
                        name="mobile"
                        fullWidth
                        inputProps={{ minLength: 10, maxLength: 10 }}
                        onChange={(e) => setMobile(e.target.value)}
                        type="number"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2} className="textfields">
                {/* <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    control={<Checkbox required defaultChecked />}
                    label={
                      <label>
                        I accept{" "}
                        <Link
                          href="/privacy-policy.php"
                          
                          rel="noopener noreferrer"
                          style={{ color: "#1976d2" }}
                        >
                          terms and conditions{" "}
                        </Link>
                        and the
                        <Link
                          href="/privacy-policy.php"
                          
                          rel="noopener noreferrer"
                          style={{ color: "#1976d2" }}
                        >
                          {" "}
                          privacy policy
                        </Link>
                      </label>
                    }
                  />
                </Grid> */}
                <Grid item xs={12} sm={12}>
                  <DialogActions>
                    <Button
                      variant="contained"
                      name="scontact"
                      type="submit"
                      id="scontact"
                      className="btn ss-btn active btn-16"
                      style={{
                        background: "#37a692",
                        color: "#fff",
                        transition: "all 0.5s ease",
                        fontWeight: 600,
                        outline: "none",
                      }}
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            </form>
          </Dialog>
        </div>
      </Stack>
    </Stack>
  );
}
