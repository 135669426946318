import { Stack, Typography } from "@mui/material";
import logo from "../images/piramal_logo.svg";
export default function Footer() {
  return (
    <Stack className="footer">
      <Stack className="logo">
        <img loadable="lazy" src={logo} alt="logo" width={120} />
      </Stack>
      <Stack className="tandc">
        <Typography variant="body">
          <a href="/privacy-policy.php" style={{ color: "#000" }}>
            PrivacyPolicy
          </a>
        </Typography>
        |
        <Typography variant="body">
          <a href="/privacy-policy.php" style={{ color: "#000" }}>
            Terms and Conditions
          </a>
        </Typography>
      </Stack>
      <Stack className="disclaimer_div">
        <Typography className="disclaimer" variant="subtitle2">
          This Project Is Rera Registered. <br /> This Website Is Managed By Piramal
          Revanta Developer's Authorised Marketing Partner. The content is for
          information purposes only and does not constitute an offer to avail of
          any service. Prices mentioned are subject to change without notice and
          properties mentioned are subject to availability. Images for
          representation purpose only. We offer additional services like free
          site visit pick-up drops, documentation, home loan formalities,
          registration formalities etc.
          <br /> ©2024.All rights reserved.
        </Typography>
      </Stack>
    </Stack>
  );
}
