import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";
import img1 from "../images/banner1.webp";
import img2 from "../images/banner2.webp";
import img3 from "../images/banner3.webp";
export default function Carousell(props) {
  var items = [
    {
      img: img1,
    },
    {
      img: img2,
    },
    {
      img: img3,
    },
  ];

  return (
    <Carousel>
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}

function Item(props) {
  console.log(props);
  return (
    <Paper>
      <img
        lodable="lazy"
        src={props.item.img}
        alt="carousel_img"
        className="carousel_img"
      />
    </Paper>
  );
}
