import {
  Button,
  Grid,
  inputLabelClasses,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

export default function Amenities() {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Stack className="amenities" id="amenities">
      <Typography
        className="h3"
        variant="h3"
        style={{
          fontWeight: "bold",
          textAlign: "center",
          padding: "50px",
          fontFamily: "IZYMODE",
        }}
      >
        Amenities
        <br />
        <div
          className="underline"
          style={{
            background: "#37a692",
            height: "4px",
            width: "220px",
            textAlign: "center",
            margin: "auto",
          }}
        ></div>
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          • Meditation Zone
        </Grid>
        <Grid item xs={12} sm={4}>
          • Walking Pathways
        </Grid>
        <Grid item xs={12} sm={4}>
          • Kids’ Play Area
        </Grid>
        <Grid item xs={12} sm={4}>
          • Butterfly Garden
        </Grid>
        <Grid item xs={12} sm={4}>
          • Swimming Pool
        </Grid>
        <Grid item xs={12} sm={4}>
          • Poolside Deck
        </Grid>
        <Grid item xs={12} sm={4}>
          • Multipurpose Hall
        </Grid>

        <Grid item xs={12} sm={4}>
          • Multipurpose Indoor Studio
        </Grid>
        <Grid item xs={12} sm={4}>
          • Indoor Games Zone
        </Grid>
        <Grid item xs={12} sm={4}>
          • Gymnasium
        </Grid>
        <Grid item xs={12} sm={4}>
          • Badminton Court
        </Grid>
        <Grid item xs={12} sm={4}>
          • Squash Court
        </Grid>
        <Grid item xs={12} sm={4}>
          • Senior Citizen Area
        </Grid>
        <Grid item xs={12} sm={4}>
          • Kids’ School
        </Grid>
        <Grid item xs={12} sm={4}>
          • Cafe/Restaurant Area
        </Grid>
        <Grid item xs={12} sm={4}>
          • Mini Theatre
        </Grid>
        <Grid item xs={12} sm={4}>
          • First Aid Room
        </Grid>
        <Grid item xs={12} sm={4}>
          • Surrounding Waterfall
        </Grid>
        <Grid item xs={12} sm={4}>
          • Entry Portal
        </Grid>
        <Grid item xs={12} sm={4}>
          • City Connectivity          
        </Grid>
        <Grid item xs={12} sm={4}>
          • Station Area Accessibility
        </Grid>
      </Grid>
    </Stack>
  );
}
