import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  inputLabelClasses,
  Link,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import logo from "../images/piramal_logo.svg";
import CloseIcon from "@mui/icons-material/Close";
import Carousell from "./carousel";

export default function Home() {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const [open, setOpen] = useState(true);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  setInterval(() => {
    if (window.localStorage.getItem("25south") !== "true") {
      handleClickOpen();
    }
  }, 20000);
  return (
    <header id="header" className="header">
      <a
        href="tel:+91-8369220494"
        tabIndex="-1"
        data-restore-tabindex="0"
        style={{ zIndex: "3", position: "absolute" }}
      >
        <span style={{ fontStyle: "italic" }}>
          <span id="appt_txt">
            <span className="color_14">
              <span style={{ textDecoration: "underline" }}>
                <span
                  style={{
                    fontFamily:
                      "avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif",
                  }}
                >
                  <h6
                    style={{
                      letterSpacing: "0.05em",
                      fontWeight: "bold",
                      textShadow: "0px 8px 27px",
                    }}
                    className="appt_text"
                  >
                    CALL FOR APPOINTMENTS : +91 - 8369220494
                  </h6>
                </span>
              </span>
            </span>
          </span>
        </span>
      </a>
      {/* <div style={{ zIndex: "3", position: "absolute", top: "23%" }}>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Grid item xs={12} sm={3.5}>
            <Stack id="projectConfig">
              <Stack className="top-offer left">
                <Stack className="head1">
                  <Typography variant="h3">25 South</Typography>
                  <Typography variant="body">At Prabhadevi, Mumbai</Typography>
                </Stack>

                <br />
                <Typography variant="body">
                  PAY JUST 20% NOW <br /> AND WAKE UP TO 7-STAR SEA <br /> VIEWS
                  FOR LIFE.
                </Typography>
                <br />
                <Typography variant="h6" className="head4">
                  LAVISH 3, 4 & 5 BEDROOM <br /> OCEANSCAPE RESIDENCES.
                </Typography>

                <br />

                <Button
                  onClick={handleClickOpen}
                  variant="contained"
                  className="btn ss-btn active btn-16 homebtn"
                >
                  Enquire Now
                </Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={3} className="contact_form">
            <form method="post" action={"enquiry-form.php"} className="right">
              <Typography
                variant="h6"
                style={{
                  color: "#FFF",
                  padding: "8px",
                  textShadow: "0px 0px 30px",
                }}
              >
                Register before the world comes to know
              </Typography>

              <Grid container spacing={5} className="textfields">
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Name"
                    value={name}
                    name="name"
                    fullWidth
                    onChange={(e) => setName(e.target.value)}
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{
                      sx: {
                        color: "white",
                        [`&.${inputLabelClasses.shrink}`]: {
                          // set the color of the label when shrinked (usually when the TextField is focused)
                          color: "white",
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "white",
                        },
                        "&:hover fieldset": {
                          borderColor: "white",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "white",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Email"
                    value={email}
                    name="email"
                    fullWidth
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{
                      sx: {
                        color: "white",
                        [`&.${inputLabelClasses.shrink}`]: {
                          // set the color of the label when shrinked (usually when the TextField is focused)
                          color: "white",
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "white",
                        },
                        "&:hover fieldset": {
                          borderColor: "white",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "white",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Mobile Number"
                    value={mobile}
                    name="mobile"
                    type="tel"
                    fullWidth
                    onChange={(e) => setMobile(e.target.value)}
                    inputProps={{
                      style: { color: "white" },
                      minLength: 10,
                      maxLength: 10,
                    }}
                    InputLabelProps={{
                      sx: {
                        color: "white",
                        [`&.${inputLabelClasses.shrink}`]: {
                          // set the color of the label when shrinked (usually when the TextField is focused)
                          color: "white",
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "white",
                        },
                        "&:hover fieldset": {
                          borderColor: "white",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "white",
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                  <Button
                    name="scontact"
                    type="submit"
                    id="scontact"
                    variant="contained"
                    className="btn ss-btn active btn-16 homebtn"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div> */}
      {/* <Carousel style={{ position: "relative" }}>
        <img loadable="lazy" src={img} alt="img" style={{ height: "100vh" }} />
      </Carousel> */}

      <Typography variant="h1" className="bannerTxt">
        Welcome To <br />
        The Bespoke Life
      </Typography>
      <Carousell />
      <div className="banner-configurations-site-visit">
        {/* Dialog */}
        <Dialog open={open} onClose={handleClose} maxWidth="xs">
          <DialogTitle
            style={{
              textAlign: "center",
              margin: "5px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <img
              lodable="lazy"
              className="logo"
              src={logo}
              alt="logo"
              width={120}
            />
            <IconButton
              aria-label="delete"
              size="large"
              style={{ outline: "none", border: "none" }}
              onClick={handleClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </DialogTitle>

          <form
            method="post"
            action="enquiry-form.php"
            style={{ padding: "20px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} style={{ display: "flex" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      style={{
                        background: "#37a692",
                        color: "#FFF",
                        padding: "3px",
                        fontWeight: "bold",
                        fontSize: "14px",
                        textAlign: "center",
                      }}
                      variant="subtitle1"
                    >
                      Discount upto 20 Lakhs*
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      style={{
                        background: "#37a692",
                        color: "#FFF",
                        padding: "3px",
                        fontWeight: "bold",
                        fontSize: "14px",
                        textAlign: "center",
                      }}
                      variant="subtitle1"
                    >
                      Best Price guaranteed
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      style={{
                        background: "#37a692",
                        color: "#FFF",
                        padding: "3px",
                        fontWeight: "bold",
                        fontSize: "14px",
                        textAlign: "center",
                      }}
                      variant="subtitle1"
                    >
                      Offer valid for limited period
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      size="small"
                      required
                      id="outlined-required"
                      label="Name"
                      value={name}
                      name="name"
                      fullWidth
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      size="small"
                      required
                      id="outlined-required"
                      label="Email"
                      value={email}
                      name="email"
                      fullWidth
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      size="small"
                      required
                      id="outlined-required"
                      label="Mobile Number"
                      value={mobile}
                      name="mobile"
                      fullWidth
                      inputProps={{ minLength: 10, maxLength: 10 }}
                      onChange={(e) => setMobile(e.target.value)}
                      type="number"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="textfields">
              {/* <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={<Checkbox required defaultChecked />}
                  label={
                    <label>
                      I accept{" "}
                      <Link
                        href="/privacy-policy.php"
                        
                        rel="noopener noreferrer"
                        style={{ color: "#1976d2" }}
                      >
                        terms and conditions{" "}
                      </Link>
                      and the
                      <Link
                        href="/privacy-policy.php"
                        
                        rel="noopener noreferrer"
                        style={{ color: "#1976d2" }}
                      >
                        {" "}
                        privacy policy
                      </Link>
                    </label>
                  }
                />
              </Grid> */}
              <Grid item xs={12} sm={12}>
                <DialogActions>
                  <Button
                    variant="contained"
                    name="scontact"
                    type="submit"
                    id="scontact"
                    className="btn ss-btn active btn-16"
                    style={{
                      background: "#37a692",
                      color: "#fff",
                      transition: "all 0.5s ease",
                      fontWeight: 600,
                      outline: "none",
                    }}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Grid>
            </Grid>
          </form>
        </Dialog>
      </div>
    </header>
  );
}
